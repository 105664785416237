import { AxiosResponse } from "axios";
import Vue from "vue";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { time } from "highcharts";

export interface StartAndEndTime {
  startTime: string;
  endTime: string;
}

const _toString = Object.prototype.toString;

function isPlainObject(obj: any): boolean {
  return _toString.call(obj).slice(8, -1) === "Object";
}

function extend(to: any, from: any): any {
  if (!to) {
    return from;
  }
  if (!from) return to;

  for (let key in to) {
    to[key] = from[key];
  }

  return to;
}

export class Util {
  public clearObject<T>(arg: T): T {
    const newObject: any = {};
    for (const key in arg) {
      if (Object.prototype.toString.call(arg[key]) === "[object Array]") {
        newObject[key] = [];
      } else {
        newObject[key] = "";
      }
    }

    return newObject;
  }

  public getId(id: string | undefined | null): string {
    return id ? id : "";
  }

  public static dateUtil(date: string | undefined | Date): string {
    if (!date) {
      return "";
    }
    const getDate = new Date(date);

    return `${getDate.getFullYear()}-${this.fillZero(
      getDate.getMonth() + 1
    )}-${this.fillZero(getDate.getDate())}`;
  }

  public static fillZero(count: number | string): string {
    return count < 10 ? "0" + count : String(count);
  }

  public static parseTime(
    time: string | undefined | Date | number,
    cFormat: string
  ): string | null {
    if (arguments.length === 0 || !time) {
      return null;
    }
    let newtimeStr: string = "";
    let newtimeNum: number = 0;
    const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
    let date: Date;

    console.log(time, "time");
    if (time instanceof Date) {
      date = time;
    } else {
      if (typeof time === "string") {
        if (/^[0-9]+$/.test(time)) {
          // support "1548221490638"
          newtimeNum = parseInt(time);
        } else {
          // support safari
          // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
          newtimeStr = time.replace(new RegExp("-", "gm"), "/");
        }
      } else if (typeof time === "number") {
        newtimeNum = time;
      }

      if (newtimeNum.toString().length === 10) {
        newtimeNum = newtimeNum * 1000;
      }

      console.log(newtimeNum, "newtimeNum", newtimeStr, "newtimeStr");
      date = new Date(newtimeNum || newtimeStr);
    }

    console.log(date, "date");
    const formatObj: any = {
      y: date.getFullYear(),
      m: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      i: date.getMinutes(),
      s: date.getSeconds(),
      a: date.getDay(),
    };
    const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
      const value = formatObj[key];
      // Note: getDay() returns 0 on Sunday
      if (key === "a") {
        return ["日", "一", "二", "三", "四", "五", "六"][value];
      }
      return value.toString().padStart(2, "0");
    });
    return time_str;
  }

  public static async sendApi(
    url: string,
    data: any = {},
    options: any,
    success: Function,
    fail: Function
  ) {
    const defaultOptions: RequestInit = {
      // credentials: 'include',
      method: "GET",
      headers: {
        // 'Accept': 'application/json',
        "Content-Type": "application/json",
      },
      mode: "cors",
      cache: "force-cache",
    };

    if (isPlainObject(options)) {
      // 只会覆盖 defaultOptions 存在的key的属性值
      extend(defaultOptions, options);
    }

    if (defaultOptions.method === "GET") {
      // 数据拼接字符串
      let dataStr = "";
      Object.keys(data).forEach((key) => {
        dataStr += key + "=" + data[key] + "&";
      });

      if (dataStr !== "") {
        dataStr = dataStr.slice(0, dataStr.lastIndexOf("&"));
        url = url + "?" + dataStr;
      }
    }

    if ("fetch" in window) {
      try {
        const response = await fetch(url, defaultOptions);
        const responseJson =
          options.repsonseType == "blob"
            ? await response.blob()
            : await response.json();

        success(responseJson);
      } catch (error) {
        // throw new Error(error)
        fail(error);
      }
    } else {
      let requestObj: any;
      if (window.XMLHttpRequest) {
        requestObj = new XMLHttpRequest();

        if (options.repsonseType == "blob") {
          requestObj.responseType = "blob";
        }
      }
      // } else {
      //   requestObj = new ActiveXObject()
      // }
      let sendData = "";
      if (defaultOptions.method === "POST") {
        sendData = JSON.stringify(data);
      }

      console.log(url, "url");

      requestObj.open(defaultOptions.method, url, true);
      requestObj.setRequestHeader(
        "Content-type",
        "application/x-www-form-urlencoded"
      );
      requestObj.send(sendData);

      requestObj.onreadystatechange = () => {
        if (requestObj.readyState === 4) {
          if (requestObj.status === 200) {
            let obj = requestObj.response;
            if (typeof obj !== "object") {
              obj = JSON.parse(obj);
            }
            success(obj);
          } else {
            fail(requestObj);
          }
        }
      };
    }
  }

  public static blobFileSaveAs(blob: Blob, filename: string) {
    const navigator: any = window.navigator;
    if ("msSaveOrOpenBlob" in window.navigator) {
      navigator.msSaveBlob(blob, filename);
    } else {
      var link = document.createElement("a");

      var body: any = document.querySelector("body");

      link.href = window.URL.createObjectURL(blob);

      link.download = filename;
      // fix Firefox

      link.style.display = "none";

      body.appendChild(link);

      link.click();

      body.removeChild(link);

      window.URL.revokeObjectURL(link.href);
    }
  }

  public static downloadOtherFlieByNetworkLink(link: string) {
    if (!link) {
      Message.error('无效链接，无法下载文件')
      return
    }
    var aLink = document.createElement("a");

    var body: any = document.querySelector("body");

    // console.log(aLink, 'aLink')
    // console.log(body, 'body')

    // fix Firefox

    aLink.href = link || ''

    aLink.target = '_top'

    aLink.style.display = "none";

    body.appendChild(aLink)

    aLink.click();

    body.removeChild(aLink);
  }


  // 生成封禁时间的所有可选值
  public static getFoibiddenTimeList() {
    const outerList = [];
    const day2second = 24 * 60 * 60;

    for (let i = 1; i <= 7; i++) {
      outerList.push({
        label: `${i}天`,
        value: i * day2second, // 单位秒
      });
    }

    const otherSingleDays = [15, 30];

    for (const count of otherSingleDays) {
      outerList.push({
        label: `${count}天`,
        value: count * day2second, // 单位秒
      });
    }

    return outerList;
  }

  //生成随机数
  public static uuid() {
    var s: any[] = [];
    var hexDigits: string = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
      const startIndex = Math.floor(Math.random() * 0x10);
      s[i] = hexDigits.slice(startIndex, startIndex + 1);
    }
    s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
    const start19Index = (s[19] & 0x3) | 0x8;
    s[19] = hexDigits.slice(start19Index, start19Index + 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-";

    var uuid = s.join("");
    return uuid;
  }

  public static dateTimeUtil(dateTime: string | undefined | Date): string {
    if (!dateTime) {
      return "";
    }
    const date = this.dateUtil(dateTime);
    const getDate = new Date(dateTime);
    return `${date} ${this.fillZero(getDate.getHours())}:${this.fillZero(
      getDate.getMinutes()
    )}:${this.fillZero(getDate.getSeconds())}`;
  }

  public static dateTimeUtilnohms(dateTime: string | undefined | Date): string {
    if (!dateTime) {
      return "";
    }
    const date = this.dateUtil(dateTime);
    console.log(date);
    // const getDate = new Date(dateTime);
    return `${date}`;
  }

  public static getHoursAndMinutes(date: Date): string {
    const time = new Date(date);
    return `${this.fillZero(time.getHours())}:${this.fillZero(
      time.getMinutes()
    )}`;
  }

  // 限制输入数字整数
  public static limitIntegetInput(
    val: string,
    successCb: Function,
    failCb: Function
  ) {
    const reg = new RegExp("[0-9]{1,}", "g");

    let newVal = val.match(reg);

    if (val && Array.isArray(newVal)) {
      successCb(newVal[0] || "");
    } else {
      failCb("");
    }
  }

  // 限制输入带指定小数位数的数字
  public static limitDigitalnput(
    val: string,
    successCb: Function,
    failCb: Function,
    digits: number = 1
  ) {
    const reg = new RegExp(`[0-9]{1,}(\\.)?[0-9]{0,${digits}}`, "g");

    let newVal = val.match(reg);

    if (val && Array.isArray(newVal)) {
      successCb(newVal[0] || "");
    } else {
      failCb("");
    }
  }

  public getUploading(blob: Blob, res: AxiosResponse<Blob> | string): void {
    const data = new Blob([blob], {
      type: "application/vnd.ms-excel;charset=UTF-8",
    });
    const url = URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    let fileName;

    if (typeof res === "string") {
      fileName = res;
    } else {
      fileName = res.headers["content-disposition"]
        .split("filename=")[1]
        .split(".")[1];
    }

    link.setAttribute("download", `${new Date().getTime()}.${fileName}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  public assignObject(form1: any, form2: any) {
    let form: any = form1;
    let otherForm: any = form2;
    for (const i in form) {
      form[i] = otherForm[i];
    }
  }

  public getSexNumber(sex: string): string {
    let result: string = sex;
    if (sex === "男" || sex === "女") {
      if (sex === "男") {
        result = "1";
      } else {
        result = "0";
      }
    }

    return result;
  }

  public static remToPx(rem: number): number {
    const fontSize: number = parseInt(
      <string>document.documentElement.style.fontSize
    );
    return fontSize * rem;
  }

  public static showToast(msg: string, type: string = "yes") {
    if (!sessionStorage.getItem("showToast")) {
      sessionStorage.setItem("showToast", "123");
      const vue = new Vue();
      if (type === "yes") {
        vue.$message.success(msg);
        console.log(msg);
      } else if (type === "warning") {
        vue.$message.warning(msg);
      } else {
        vue.$message.error(msg);
      }

      setTimeout(() => {
        sessionStorage.removeItem("showToast");
      }, 3000);
    }
  }

  public static handleStartTimeAndEndTime(
    time: Date[],
    hours: boolean = true
  ): StartAndEndTime {
    if (!time) {
      return {
        startTime: "",
        endTime: "",
      };
    }
    return hours
      ? {
          startTime: this.dateTimeUtil(time[0]),
          endTime: this.dateTimeUtil(time[1]),
        }
      : { startTime: this.dateUtil(time[0]), endTime: this.dateUtil(time[1]) };
  }

  public static handleStartTimeAndEndTimenohms(
    time: Date[],
    hours: boolean = true
  ): StartAndEndTime {
    if (!time) {
      return {
        startTime: "",
        endTime: "",
      };
    }
    return hours
      ? {
          startTime: this.dateTimeUtilnohms(time[0]),
          endTime: this.dateTimeUtilnohms(time[1]),
        }
      : { startTime: this.dateUtil(time[0]), endTime: this.dateUtil(time[1]) };
  }

  public static animation(dom: HTMLElement) {
    requestAnimationFrame(() => {
      if (dom.scrollHeight - dom.scrollTop === dom.clientHeight) {
        dom.scrollTop = 0;
      }
      // if (dom.scrollTop === 0) {
      //     flag = false;
      // }
      dom.scrollTop += 1;

      this.animation(dom);
    });
  }

  public static getDay(day: number) {
    const today = new Date();

    const targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;

    today.setTime(targetday_milliseconds); //注意，这行是关键代码

    const tYear = today.getFullYear();
    let tMonth: string | number = today.getMonth();
    let tDate: string | number = today.getDate();
    tMonth = Util.fillZero(tMonth + 1);
    tDate = Util.fillZero(tDate);
    return tYear + "-" + tMonth + "-" + tDate;
  }

  public static fmoney(s: string, n: number) {
    n = n >= 0 && n <= 20 ? n : 2;
    if (n === 0) {
      s = parseFloat((s + "").replace(/[^\d\.-]/g, "")) + "";
    } else {
      s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
    }
    let l = s.split(".")[0].split("").reverse();
    let r = n === 0 ? "" : s.split(".")[1];
    let t: string = "";
    for (let i = 0; i < l.length; i++) {
      t += l[i] + ((i + 1) % 3 === 0 && i + 1 !== l.length ? "," : "");
    }
    return n === 0
      ? t.split("").reverse().join("")
      : t.split("").reverse().join("") + "." + r;
  }
  public static fmoney2(s: string) {
    // if (s === '0') {
    //     return  '0';
    // }

    const wan = s.length - 4 <= 0 ? "" : s.slice(0, s.length - 4) + "万";
    return wan + s.slice(s.length - 4, s.length) + "元";
  }

  //防抖

  /**
   * @param func 需要包装的函数
   * @param delay 延迟时间，单位ms
   * @param immediate 是否默认执行一次(第一次不延迟)
   */
  public debounce(
    func: Function,
    delay: number,
    immediate: boolean = false
  ): Function {
    let timer: number | undefined;

    return (...args: any) => {
      if (immediate) {
        func.apply(this, args); // 确保引用函数的指向正确，并且函数的参数也不变
        immediate = false;
        return;
      }
      clearTimeout(timer);
      timer = setTimeout(() => {
        console.log("防抖中");
        func.apply(this, args);
      }, delay);
    };
  }

  //验证手机号
  public static checkMobile(date: string): boolean {
    // 开头的"1"代表第一位为数字1，"[3-9]"代表第二位可以为3、4、5、6、7、8、9其中之一，"\\d{9}"代表后面是9位数字
    const reg = new RegExp(
      "^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\\d{8}$"
    );
    // 字符串变量的matches方法返回正则表达式对该串的检验结果，true表示符合字符串规则，false表示不符合规则
    if (reg.test(date)) {
      return true;
    } else {
      return false;
    }
  }



  // 获取本月时间
  public getCurrentMonth() {
    // if(value==''|| value==undefined){
    //     return value
    // }
    // if(value.length==10){
    //     value = value *1000
    // }
    // let nowdays = new Date(value)
    let nowdays = new Date();
    let year = nowdays.getFullYear();
    let month: any = nowdays.getMonth() + 1;

    if (month < 10) {
      month = "0" + month;
    }

    let yDate = new Date(year, month, 0);

    let startDateTime = year + "-" + month + "-01"; //上个月第一天
    let endDateTime = year + "-" + month + "-" + yDate.getDate(); //上个月最后一天

    return [startDateTime, endDateTime];
  }
}

//  获取 可以暴露的 API 接口数据结构

export function getPageAPIs(fn: any) {
  if (typeof fn != 'function') {
    throw new TypeError(`fn is not Function`)
  }

  const ins = new fn()

  // console.log(ins, 'ins')

  const apiUrlMaps = ins.apiUrls;
  const apiList: any[]  = []

  for (const [_, pageApiUrls] of apiUrlMaps) {
    if (!pageApiUrls.hidden) {
      apiList.push({
        ...pageApiUrls
      })
    }
  }

  return apiList
}
